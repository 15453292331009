<template>
  <div class="potential">
    <rxNavBar :title="navTitle"></rxNavBar>
    <loading v-if="loadingFlag"></loading>
    <van-tabs v-model="active"
              line-width="20"
              line-height="5"
              title-active-color="rgb(255,81,45)"
              sticky
              offset-top="1.33rem" @scroll="scroll"
    >
      <van-tab title="详情">
        <div class="basicInfo">
          <div class="panel">
            <div :class="'state top-title top-title-'+detailValid.approvalStatus">{{detailValid.approvalStatusStr}}</div>
            <van-row class="info">
              <van-col span="12">
                <p>离职员工：<span>{{detailValid.staffName}}</span></p>
              </van-col>
              <van-col span="12">
                <p>性别：<span>{{detailValid.gender}}</span></p>
              </van-col>
              <van-col span="12">
                <p>手机：<span>{{detailValid.mobile}}</span></p>
              </van-col>
              <van-col span="12">
                <p>{{detailValid.certificateTypeStr}}：<span>{{detailValid.idCard}}</span></p>
              </van-col>
              <van-col span="24">
                <p>所属部门：<span>{{detailValid.departmentName}}</span></p>
              </van-col>
              <van-col span="24">
                <p>职务：<span>{{detailValid.dutyName}}</span></p>
              </van-col>
              <van-col span="24">
                <p>公司：<span>{{detailValid.companyname}}</span></p>
              </van-col>
              <van-col span="24">
                <p>预计离职时间：<span>{{detailValid.operDate}}</span></p>
              </van-col>
              <van-col span="24">
                <p>离职原因：<span>{{detailValid.leaveReason}}</span></p>
              </van-col>
              <van-col span="24">
                <p>交接人：<span>{{detailValid.handoverStaffName}}</span></p>
              </van-col>
              <van-col span="12">
                <p>上级：<span>{{detailValid.seniorStaffName}}</span></p>
              </van-col>
              <van-col span="12">
                <p>上上级：<span>{{detailValid.superiorStaffName}}</span></p>
              </van-col>
            </van-row>
          </div>
          <p class="subtitle">离职办理信息</p>
          <div class="panel">
            <van-row class="info">
              <van-col span="24">
                <p>发起人：<span>{{detailValid.addStaffName}}</span></p>
              </van-col>
              <van-col span="24">
                <p>发起时间：<span>{{detailValid.addTime}}</span></p>
              </van-col>
              <van-col span="24" v-if="detailValid.approvalStatus==='5'">
                <p>办理人：<span>{{detailValid.finishStaffName}}</span></p>
              </van-col>
              <van-col span="24" v-if="detailValid.approvalStatus==='5'">
                <p>办理时间：<span>{{detailValid.finishTime}}</span></p>
              </van-col>
              <van-col span="24" v-if="detailValid.approvalStatus==='7'">
                <p>撤销时间：<span>{{detailValid.revokeTime}}</span></p>
              </van-col>
              <van-col span="24" v-if="detailValid.approvalStatus==='7'">
                <p>撤销原因：<span>{{detailValid.revokeRemark===''?'暂无':detailValid.revokeRemark}}</span></p>
              </van-col>
            </van-row>
          </div>


          <p class="subtitle" v-if="enterType==='approve'">上传附件</p>
          <div class="learningAttachments" v-if="enterType==='approve'">
            <van-uploader
                    v-model="fileList"
                    upload-icon="plus"
                    multiple
                    image-fit="contain"
                    :after-read="afterReadTest"
                    @click-preview="clickFile"
                    :before-read="beforeRead"
                    :max-size="5 * 1024 * 1024"
                    @oversize="onOversize"
                    accept=""
          >
          </van-uploader>
              </div>



          <p class="subtitle" v-if="enterType==='approve'">审批</p>
          <div v-if="enterType==='approve'">
            <div class="panel radio_body withMargin">
              <p style="marginBottom:15px">
                <span :class="isChoose ? 'dot is_dot' : 'dot'"></span>
                审核结果
              </p>
              <div class="choose">
                <div :class="isPass == 1?'choose_btn pass': 'choose_btn'" @click="multipleChoice(1)">通过</div>
                <div class="line"></div>
                <div :class="isPass == 0?'choose_btn reject': 'choose_btn'" @click="multipleChoice(0)">驳回</div>
              </div>
            </div>
            <div id="cleanId" v-show="refuseTypeShow" class="part-inputpart-row" @click="refuseReasonShow = !refuseReasonShow">
              <span :class="!selectRefuseReason.dictionaryTitle? 'notdot' : 'isdot'" > </span>
              <span class="part-inputpart-row-header">原因</span>
              <span class="divide">|</span>
              <span class="part-inputpart-row-graytext" :class="selectRefuseReason.dictionaryTitle ? 'part-inputpart-row-normaltext':'' ">{{selectRefuseReason.dictionaryTitle}}</span>
              <img class="part-inputpart-row-right" :class=" refuseReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
            </div>

            <div class="panel">
              <p class="yijian">审批意见</p>
              <div class="text_body">
                <van-field v-model="selectRefuseReason.approvalOpinion"  type="textarea" class="mes_body" />
              </div>
            </div>
            <div class="sub_btn">
              <van-button  :class="checkApprovalResult() ?'saveButton_Enable':'saveButton_Disable'" :disabled="!checkApprovalResult()" size="large" @click="save">保存
              </van-button>
            </div>
          </div>
          <van-popup v-model="refuseReasonShow" position="bottom">
            <van-picker
                show-toolbar
                :columns="refuseReasonList"
                @cancel="refuseReasonShow = false"
                :default-index="selectRefuseReason.refuseReasonIndex"
                value-key="dictionaryTitle"
                @confirm="selectRefuseReasonOption"
            />
          </van-popup>
        </div>
      </van-tab>
      <van-tab title="审批">
        <div class="approve" v-if="approvalHistroyList.length>0">
          <div class="time_line">
            <div class="item" v-for="(item,index) in approvalHistroyList" :key="index">
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:index===0}"></div>
                <div class="point"></div>
                <div class="line line_bottom" v-if="index != approvalHistroyList.length - 1"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <p class="total_color">
                    {{item.approvalResultStr}}
                    <span style="display: inline-block;margin-left: 0.2rem" v-if="item.approvalResult==='1'">{{item.dictionaryTitle}}</span>
                    <span class="floatR small_font">{{item.approvalTime}}</span>
                  </p>
                  <p>
                    {{item.staffName}}
                    <span style="display: inline-block;margin-left: 0.2rem">{{item.approvalTypeStr}}</span>
                  </p>
                  <p>
                    <span style="display: inline-block;">{{item.remarks}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <common-empty v-else></common-empty>
      </van-tab>
    </van-tabs>


   <!-- 预览弹框 -->
    <van-popup
      v-model="recomShows"
      class="van-popup--bottom"
      position="bottom"
      :style="{ height: 'auto' }"
    >
      
        <div  v-if="type=='ssqian'">
          <iframe style="height: 500px; width: 10rem; display: block;"
                :src="electronicContractUrl" frameborder=0 name="showHere" scrolling=auto
        ></iframe>
        </div>

        <div v-if="type=='fxqian'" style="height: 500px;overflow-y:auto;overflow-x:hidden;">
            <div v-for="(item,index) in numPages" :key="index">
              <pdf
                :src="electronicContractUrl"
                ref="pdf"
                :page="index + 1"
              ></pdf>
          </div>  
        </div>

      <div style="display: flex; text-align: center">
        <div style="width: 70%">
          <!-- <van-button @click="comfirmss" class="saveButton_able"
            >确认</van-button
          > -->
        </div>
        <div style="width: 30%">
          <van-button @click="close" class="enable">取消</van-button>
        </div>
      </div>
    </van-popup>      


  </div>
</template>

<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {Button, Col, DropdownItem, DropdownMenu, Field, Picker, Popup, Row, Tab, Tabs, uploader, Image as VanImage,Loading} from "vant";
import pdf from 'vue-pdf'
import {approvalAgree, approvalRefuse, browseEmploymentApprovalDetail, queryBaseData, saveFile, addPic} from "../../../getData/getData";
import {getStaffId, responseUtil, globaluserId} from "../../../libs/rongxunUtil";
import * as imageConversion from 'image-conversion'


export default {
  name: "staffDepartDetail",
  components:{
    rxNavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [DropdownMenu.name]:DropdownMenu,
    [DropdownItem.name]:DropdownItem,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [uploader .name]:uploader,
    pdf,
    [Loading .name]:Loading,
  },
  data(){
    return{
      fileList:[],
      recomShows: false,
      numPages: '',
      loadingFlag:false,
      fileExtension: 'pdf, txt, doc, docx, xls, xlsx, ppt, pptx, jpg, png',

      offsetTop:'',
      navTitle:'',
      enterType:'',
      active: 0,
      detailValid:{},
      approvalHistroyList:[],

      isChoose:true,  //是否选择审核结果
      isPass:1,  //是否选择通过
      refuseTypeShow:false,
      refuseReasonShow:false,  //拒绝原因弹框
      selectRefuseReason:{},
      refuseReasonList:[],

      notClickable:false,


      electronicContractUrl: '',
      type: '',
      title: '',
      rowId: '',
    }
  },
  activated() {
    this.active = 0
    this.enterType = this.$route.query.enterType
    this.navTitle = this.$route.query.enterType==='detail'?'离职详情':'离职详情'
    this.initStaffEntryDetail()
    this.initDictionaryData()
    this.fileList = []
  },
  mounted() {
     console.log("routerDate:",this.$route.query)
    this.rowId =  this.$route.query.id
    this.active = 0
    this.enterType = this.$route.query.enterType
    this.navTitle = this.$route.query.enterType==='detail'?'离职详情':'离职详情'
    this.initStaffEntryDetail()
    this.initDictionaryData()
  },
  methods:{
    //图片多选上传
    afterReadTest(file){
      this.loadingFlag = true;
      let fileLength = parseInt(file.length) - parseInt(1)
      if(file.length > 1){
        for(let i = 0; i<file.length;i++){
          let fileDetail = file[i];
          this.afterRead(fileDetail,i,fileLength);
        }
      }else{
        this.afterRead(file);
      }
    },
    afterRead(file,i,fileLength) {
      console.log("file",file)
      console.log("fileLength",fileLength)
      let that = this
      let initData = {
        base64 : file.content
      }
      that.loadingFlag = true;
      saveFile(initData).then(function (response) {
        // that.loadingFlag = false;
        responseUtil.dealResponse(that, response, () => {
          file.url = response.data.data.path
          file.name = file.file.name
          if(i == fileLength){
            that.loadingFlag = false
          }else if(!fileLength || fileLength == undefined){
            that.loadingFlag = false
          }
        })
      })
    },
     close() {
      this.recomShows = false;
    },
    // 文件上传大小限制
    onOversize(file) {
      responseUtil.alertMsg(this,'文件大小不能超过5MB！')
    },
     beforeRead(file) {
      this.loadingFlag = true;
      if (file instanceof Array) {
        console.log("Array:")
         for (const i in file) {
          let extension = this.getFileExtension(file[i].name)
          let isLimit = this.fileExtension.includes(extension)
              if(extension == 'png' || extension == 'jpg'){
              return new Promise(async (resolve, reject) => {
              let newarr = []
              // 500是控制压缩大小的 根据你自己的需要调整 数值越小压缩越小
              for (const i in file) {
                console.log('压缩前', file[i]) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
                const res = await imageConversion.compressAccurately(file[i], 400)
                const newfile = new File([res], file[i].name, { type: res.type, lastModified: Date.now() })
                newarr[i] = newfile
              
              }
                resolve(newarr)
              })
            }

             if(!isLimit){
              responseUtil.alertMsg(this,'暂不支持打开此类文件,请上传 pdf, txt, doc, docx, xls, xlsx, ppt, pptx, jpg, png格式文件')
              this.loadingFlag = false;
              return false
              }else{
                this.loadingFlag = false;
                return true
              }

          }
          this.loadingFlag = false;


      } else {
        console.log("single:")
          let extension = this.getFileExtension(file.name)
          let isLimit = this.fileExtension.includes(extension)
          if(!isLimit){
            responseUtil.alertMsg(this,'暂不支持打开此类文件,请上传 pdf, txt, doc, docx, xls, xlsx, ppt, pptx, jpg, png格式文件')
            this.loadingFlag = false;
            return false
          }else{
            if(extension == 'png' || extension == 'jpg'){
                return new Promise((resolve, reject) => {
                console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
                // 500是控制压缩大小的 根据你自己的需要调整 数值越小压缩越小
                imageConversion.compressAccurately(file, 400).then(res => {
                  res = new File([res], file.name, { type: res.type, lastModified: Date.now() })
                  console.log('压缩后', res)
                  this.loadingFlag = false;
                  resolve(res)
                })
              })
            } else {
                 this.loadingFlag = false;
                 return true
            }
             
          }
          this.loadingFlag = false;
      }

     
    },
    // 获得文件后缀名
    getFileExtension(name){
      const extension = name.substring(name.lastIndexOf('.') + 1).toLowerCase() // 文件后缀
      return extension
    },
     //点击查看文件
    clickFile(file){
      console.log(file)
      let extensionThr = file.url.substr(-3)
      let extensionFour = file.url.substr(-4)
      let ppyExtThr = file.name.substr(-3)
      let ppyExtFou = file.name.substr(-4)
      if(extensionThr==='txt' || extensionThr==='xls' || ppyExtThr==='ppt' || ppyExtFou==='pptx' || extensionFour==='xlsx'){
         this.type = "ssqian"
         this.electronicContractUrl =  'https://view.officeapps.live.com/op/view.aspx?src=' + file.url
         this.title = file.file.name
         this.recomShows = true;

        // this.$router.push({
        //   name:'electronicContract',
        //   query:{
        //     type:'ssqian',
        //     electronicContractUrl: file.url,
        //     title:file.file.name,
        //   }
        // });
      }else if(extensionThr==='pdf'){
        this.type = "fxqian"
         this.electronicContractUrl = file.url
         this.title = file.file.name
         this.recomShows = true;
        this.getNumPages();

        // this.$router.push({
        //   name:'electronicContract',
        //   query:{
        //     type:'fxqian',  //pdf的标识
        //     electronicContractUrl: file.url,
        //     title:file.file.name
        //   }
        // });
      }else if(extensionThr==='doc'  || extensionFour==='docx'){
        this.$router.push({
          name:'electronicContract',
          query:{
            type:'ssqian',
            electronicContractUrl: file.url,
            title:file.file.name,
          }
        });

      }else if(extensionThr==='png'|| extensionThr==='jpg'){
        return;
      }else{
        // responseUtil.alertMsg(this,'暂不支持打开此类文件')
        // return
      }
    },
     getNumPages() {
            let loadingTask = pdf.createLoadingTask(this.electronicContractUrl)
            loadingTask.promise.then(pdf => {
              this.numPages = pdf.numPages
            }).catch(err => {
              console.error('pdf 加载失败', err);
            })
          },
    initStaffEntryDetail(){
      let that = this
      let initData = {}
      initData.id = this.$route.query.id
      initData.approvalType = 1
      browseEmploymentApprovalDetail(initData).then(function (response){
        that.detailValid = response.data.data.data
        that.approvalHistroyList = response.data.data.approvalList
        that.detailValid.seniorStaffName = that.$route.query.seniorStaffName
        that.detailValid.superiorStaffName = that.$route.query.superiorStaffName

        // that.srcList.push(response.data.data.data.path)
      })
    },
    //选择审批意见
    multipleChoice(isPass) {
      if(isPass == 1){
        this.refuseTypeShow = false
      }else {
        this.refuseTypeShow = true
      }
      //审核结果
      this.isChoose = true;
      //this.grade= 0;
      this.isPass = isPass
    },
    //选择拒绝原因
    selectRefuseReasonOption(item,index){
      this.selectRefuseReason.refuseReason_id = item.id
      this.selectRefuseReason.refuseReasonIndex = index
      this.selectRefuseReason.dictionaryTitle = item.dictionaryTitle

      this.refuseReasonShow = false
    },
    savefileAdd(){
            let that=this
            that.loadingFlag = true;
            let initData={}
            let file = []
            that.fileList.filter((item,index) => {
              let map = {}
              map.name = item.name
              map.path = item.url
              file.push(map)
            })
            initData.picList=file
            initData.user_id=getStaffId()
            initData.id=that.rowId
            // if (initData.picList.length>0){
                addPic(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        that.fileList=[]
                    })
                })
            // }else{
            //     responseUtil.alertMsg(that,'请上传文件')
            //     that.loadingFlag = false;
            // }

        },
    save(){
      if(!this.checkApprovalResult()){
        responseUtil.alertMsg(this,'驳回请选择驳回原因')
        return
      }
      if(this.notClickable){
        return;
      }

      if(this.fileList.length > 0){
        console.log("this.fileList:",this.fileList)
            this.savefileAdd();
        }


      let func
      let that = this
      let initData = {}
      initData.id = this.$route.query.id
      initData.staff_id = getStaffId()
      initData.approval_id = this.$route.query.approval_id
      initData.approvalOpinion = this.selectRefuseReason.approvalOpinion
      initData.approvalType = 1
      if(this.isPass===1){  //通过
        func = approvalAgree
      }else{  //拒绝
        func = approvalRefuse
        initData.refuseReason_id = this.selectRefuseReason.refuseReason_id
      }
      this.notClickable = true
      func(initData).then(function (response){
        that.notClickable = false
        responseUtil.dealResponse(that, response, () => {
          that.$router.go(-1)
        })
      })
    },
    checkApprovalResult(){
      if(this.isPass===0){  //驳回
        if(this.selectRefuseReason.refuseReason_id==undefined || this.selectRefuseReason.refuseReason_id==''){
          return false
        }
      }
      return true
    },
    // 获取字典数据
    initDictionaryData: function () {
      let that = this
      let initData = {}
      initData.user_id = getStaffId()
      initData.dbName = ["departReject"]
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.refuseReasonList = response.data.data.departReject;
        })
      })
    },
    // 滚动时获取nav高度并赋值
    scroll() {
      if (this.$refs.navBar) {
        this.offsetTop = this.$refs.navBar.clientHeight
      }

    },
  }
}
</script>

<style scoped lang="less">
*{
  margin: 0px;
}
.potential {
  width: 100%;
  background-color: #f8f8f8;
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;
}
//小标题
.subtitle {
  padding: 5px 0px;
  color: rgb(244, 99, 76);
  margin-top: 15px;
  font-weight: 700;
  font-size: 12px;
}
//详情
.basicInfo{
  padding: 0 0.4rem;
  margin-top: 0.4rem;
}
.learningAttachments{
  width: 345px;
  box-sizing: border-box;
  // margin: 0 0.3rem;
  padding: 0.4rem;
  background-color: white;
  border-radius: 8px;
}
//基本信息
.panel {
  width: 345px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  font-size: 14px;
  text-align: left;
  overflow: hidden;


  &.withMargin {
    margin: 0px 0 16px;
  }

  &>p {
    padding: 15px 15px 0px;
    font-weight: bolder;
    /*color: #252525;*/

  }

  &>p>span {
    font-weight: normal;
    color: rgb(190, 190, 190);
  }

  .state {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
    //width: 60px;
    display: block;
    //background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
    //background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
    ///* Safari 5.1 - 6.0 */
    //background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
    ///* Opera 11.1 - 12.0 */
    //background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
    ///* Firefox 3.6 - 15 */
    //background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
    transform: scale(0.5);
    transform-origin: 0% 0%;
    font-family: sans-serif;
    padding: 0 10px;
  }
  .top-title-4{
    background: linear-gradient(to right, #7cc7ff, #3596fd);
  }
  .top-title-5{
    background: linear-gradient(to right, #63cb96, #30d0ac);
  }
  .top-title-0,.top-title-1,.top-title-2,.top-title-3{
    background: linear-gradient(to right, #ffbe72, #ff6c41);
  }
  .top-title-6{
    background: linear-gradient(to right, #cc004b, #660026);
  }
  .top-title-7{
    background: linear-gradient(to right, #bfcbd9, #a8b9cc);
  }

  .title {
    width: 315px;
    padding: 0 15px;
    font-weight: bolder;
    color: rgb(0, 0, 0);
    font-size: 16px;
    padding-top: 20px;

    span {
      font-size: 12px;
      font-weight: normal;
      color: rgba(153, 153, 153, 1);
    }


  }
  .allprice{
    padding-bottom: 4px;
  }
  /*审核结果,服务人员 审批意见*/
  .shenhe,.fuwu,.yijian{
    font-size: 15px;
  }
  .describe {
    padding-bottom: 15px;
    padding-top: 10px;
    color: rgb(82, 82, 82);
    font-weight: normal;
  }

  .info {
    padding: 15px;
    line-height: 16px;

    &.noPadding {
      padding: 0 15px;
    }

    p {
      margin: 7px 0;
      font-weight: bolder;

      span {
        font-size: 13px;
        font-weight: normal;
        color: rgb(110, 110, 110);
      }
    }

    .floatR {
      float: right;
    }

    .total_color {
      color: rgba(255, 93, 59, 1);
    }
  }

  .text_body {
    padding: 15px;

    .mes_body {
      border-radius: 8px;
      background-color: rgb(250, 250, 250);
    }
  }
}
//审批
// 单选框
.radio_body {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;

  .dot {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgb(136, 136, 136);
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 6px;

    &.is_dot {
      background-color: rgb(251, 91, 64);
    }
  }

  .line {
    width: 2px;
    height: 12px;
    background-color: rgb(250, 250, 250);
  }

  .choose {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;

    .choose_btn {
      padding: 5px;
      margin: 10px;
      color: rgb(136, 136, 136);
      cursor: pointer;

      &.pass {
        color: rgb(251, 91, 64);
        font-weight: bolder;
      }

      &.reject {
        color: red;
        font-weight: bolder;
      }
    }
  }
}
//选择
.part-inputpart-row {
  width: 345px;
  float: left;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 50px;
  border-radius: 8px;
  margin: 0 5px 15px 0;
  .notdot {
    display: inline-block;
    padding: 3.5px;
    border-radius: 50%;
    background-color: rgb(136, 136, 136);
    vertical-align: middle;
    margin-right: 13px;
    margin-left: 15px;
  }
  .isdot{
    display: inline-block;
    width: 8px;
    height: 6px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 13px;
    background-color: rgb(251,91,64);
    margin-left: 15px;
  }
  .part-inputpart-row-header{
    font-weight: bold;
    font-size: 14px;
    width: 90px;
    color: black;
  }
  .divide {
    font-size: 14px;
    color: #efefef;
    margin:0 7px 0 10px;
  }
  .part-inputpart-row-graytext {
    color: #d9d9d9;
    width: 100%;
    font-size: 14px;
  }
  .part-inputpart-row-normaltext{
    color: black;
  }
  .part-inputpart-row-right-downArrow{
    width: 9px;
    height: 9px;
    margin-right: 15px;
  }
  /*选择三角图标的向上向下样式*/
  .part-inputpart-row-right-upArrow{
    width: 9px;
    height: 9px;
    /*transform: rotateX(180deg);*/
    margin-right: 15px;
  }
}
//保存按钮
.sub_btn {
  margin: 30px 0 70px;
  /*保存按钮不可点击样式*/
  .saveButton_Disable{
    /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
    background-color: rgba(184, 184, 184, 0.2);
    color: white;
    width: 345px;
    height: 50px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 1.22rem;
    margin-left: 0;
    text-align: center;
  }
  /*保存按钮可点击样式*/
  .saveButton_Enable{
    background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
    color: white;
    width: 345px;
    height: 50px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 1.22rem;
    text-align: center;
  }
}
.enable {
  background: linear-gradient(to right, #999999, #cccccc 100%) repeat scroll 0%
    0%;
  color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}


// 时间线
.approve {
  margin-top: 20px;
  .time_line {
    .item {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .left {
        width: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        /*margin-right: 5px;*/

        .line {
          width: 1px;
          background-color: rgb(255, 75, 39);
          position: absolute;

          &.line_top {
            top: 0px;
            height: ceil(50%-8px);

            &.first_top {
              background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Safari 5.1 - 6.0 */
              background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Opera 11.1 - 12.0 */
              background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Firefox 3.6 - 15 */
              background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
            }
          }

          &.line_bottom {
            bottom: 0;
            height: ceil(50%+10px);
          }
        }

        .point {
          position: relative;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          border: 1px solid rgb(255, 75, 39);
          transform: translateY(-50%);
          z-index: 99;
          background-color: rgb(255, 255, 255);

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgb(255, 75, 39);
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }


      }

      .right {
        width: 100%;
        padding-bottom: 20px;

        .approve_panel {
          margin: 0 15px;
          border-radius: 8px;
          background-color: rgb(255, 255, 255);
          padding: 10px;

          p {
            padding-top: 10px;
            font-weight: bolder;

            &:last-of-type {
              padding-bottom: 10px;
            }

            span {
              font-weight: normal;
              color: rgb(82, 82, 82);
            }

            .floatR {
              float: right !important;
            }
          }
        }
      }
    }

  }
}
/*弹窗样式*/
.van-popup--bottom {
  /*z-index: 2004;*/
  background-color: #f8f8f8;
  overflow: hidden;
  /*padding-bottom: 60px;*/
}
</style>